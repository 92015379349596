window.__lodash__ = _.noConflict();
window.__underscore__ = _.noConflict();
_ = window.__lodash__
Vue.config.devtools = true;


Vue.use(VueRouter);
Vue.use(Vuex);
Vue.component('icon', VueAwesome);


const storeApp = new Vuex.Store({
    strict: false,
    state: defaultState,
    getters:defaultGetters,
    actions :defaultActions,
    mutations: defaultMutations
});

Vue.mixin({
    methods: {
        encodeToURL(value){
            let urlString = (value + '').toString();
            return encodeURIComponent(urlString)
                .replace('!', '%21')
                .replace('\'', '%27')
                .replace('(', '%28')
                .replace(')', '%29')
                .replace('*', '%2A')
                .replace('%20', '+');
        },
        decodeFromURL(value){
            let urlString = (value + '').toString();
            return decodeURIComponent(urlString)
                .replace('%21','!')
                .replace('%27','\'')
                .replace('%28','(')
                .replace('%29',')')
                .replace('%2A','*')
                .replace('+','%20');
        },
        getUI:function(){
            return uuidv4()
        },
        tr: function (stringOrigin) {
            //console.log('Intento de Traducción',this.$store.getters.tr(stringOrigin));
            return this.$store.getters.tr(stringOrigin);
        },
        encode:function (str) {
            let b64 = btoa(str);
            let a = b64.split("").reverse().join("");
            return a;
        },
        decode(str = "") {
            let b64 = str.split("").reverse().join("");
            return atob(b64);
        },
        formatFloat:function(number){
            try {
                if(isNaN(number))
                    return '--';
                return parseFloat(Math.round((parseFloat(number)+0.00001) * 100) / 100).toFixed(2);
            }
            catch(err){
                console.log('Error try to format number', number);
                return number
            }

        }
    }
})


const router = new VueRouter({
    mode: 'hash',
    //base: __dirname,
    routes:globalRouter,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});
router.beforeEach((to, from, next) => {
    if(to.name!="BrowserSupport"){
        let browser = detect();
        if (browser && browser.name){
            let currentVersion = parseFloat(browser.version.split(".").slice(0,2).join("."));
            if (currentVersion< browserSupportVersions[browser.name])
                router.push({name:'BrowserSupport'})
        }
    }
    /*if(from.name && to.name && to.name!=from.name) {
        //console.log('Gets beforeResolve.',to, from);
        /*setTimeout(function() {
            store.commit("updateItemItemFlag");
        }, 500);
    }*/
    if(next)
        next();
});

const app = new Vue({
    store,
    router,
    watch:{
        '$route':'redirectToUpdate',
        '$store.state.mustUpdateInfo':'redirectToUpdate',
        '$store.state.session.fields.RequireUserInfo':'redirectToUpdate'
    },
    data:()=>{
      return {
          backgroundTask:null
      }
    },
    template:`<router-view class="view oo-ecommerce"></router-view>`,
    created:function(){
        this.checkbrowser();
        this.runBackupTask();
    },
    updated:function(){
        this.checkbrowser();
    },
    methods:{
        runBackupTask(){
            clearTimeout(this.backgroundTask);
            //this.backgroundTask = setTimeout(this.priceUpdateInBackGroup,10000);
        },
        priceUpdateInBackGroup(){
            let itemWithOutPrice = Array.from(store.state.items_store.values()).filter(x=>!x.isCalculate).slice(0,100);
            this.$store.dispatch('updatePricesBackGroup',itemWithOutPrice);
            this.backgroundTask = setTimeout(this.priceUpdateInBackGroup,10000);
            console.log("RUN BACKGROUP PRICES");
        },
        redirectToUpdate:function(){
            //console.log(this.$store.state.session, this.$store.state.mustUpdateInfo, this.$store.state.session.fields.RequireUserInfo);
            if(this.$store.state.mustUpdateInfo && this.$store.state.session.fields.RequireUserInfo)
                this.$router.push('/genericuser');
        },
        checkbrowser:function(){
            let browser = detect();
            if (browser && browser.name){
                let currentVersion = parseFloat(browser.version.split(".").slice(0,2).join("."));
                if (currentVersion < browserSupportVersions[browser.name])
                    this.$router.push({name:'BrowserSupport'})
            }
        }
    },

    beforeCreate: async function(){
        this.$store.dispatch('loadLanguage');
        await this.$store.dispatch('loadSession');
    }
}).$mount('#app');

function start_v_menu(i) {

    try {
        var e = document.getElementById(i.menuId), j = e.offsetHeight, b = e.getElementsByTagName("ul"),
            g = /msie|MSIE 6/.test(navigator.userAgent);
        //console.log(e, g, j, b)
        if (g) for (var h = e.getElementsByTagName("li"), a = 0, l = h.length; a < l; a++) {
            h[a].onmouseover = function () {
                this.className = "onhover"
            };
            h[a].onmouseout = function () {
                this.className = ""
            }
        }
        for (var k = function (a, b) {
            if (a.id == i.menuId) return b; else {
                b += a.offsetTop;
                return k(a.parentNode.parentNode, b)
            }
        }, a = 0; a < b.length; a++) {
            var c = b[a].parentNode;
            // c.getElementsByTagName("a")[0].className += " arrow";
            // b[a].style.left = c.offsetWidth + "px";
            // b[a].style.top = c.offsetTop + "px";
            if (i.alignWithMainMenu) {
                var d = k(c.parentNode, 0);
                if (b[a].offsetTop + b[a].offsetHeight + d > j) {
                    var f;
                    if (b[a].offsetHeight > j) f = -d; else f = j - b[a].offsetHeight - d;
                    // b[a].style.top = f + "px"
                }
            }
            c.onmouseover = function () {
                if (g) this.className = "onhover";
                var a = this.getElementsByTagName("ul")[0];
                if (a) {
                    a.style.visibility = "visible";
                    a.style.display = "block"
                }
            };
            c.onmouseout = function () {
                if (g) this.className = "";
                this.getElementsByTagName("ul")[0].style.visibility = "hidden";
                this.getElementsByTagName("ul")[0].style.display = "none"
            }

        }
        for (var a = b.length - 1; a > -1; a--) b[a].style.display = "none"

        // cuando se hace scroll, calcular la distancia de .filter-elements del top
        window.addEventListener('scroll', function (e) {
            try {
                let st = $(this).scrollTop();
                let categoryMenuTop = $('.category-tree-container').offset().top;
                let categoryMenu = $('.category-tree-container');
                let leftSubMenu = categoryMenu.find('.category-tree-menu')[0].getBoundingClientRect().left + categoryMenu.find('.category-tree-menu')[0].offsetWidth;
                $('.scrolled-window .category-tree-menu .sub').css('left', leftSubMenu);

                if (st > categoryMenuTop) {
                    categoryMenu.addClass('scrolled-window');
                } else {
                    categoryMenu.removeClass('scrolled-window');
                }
            }
            catch(err){
                console.log('Category Tree Error',err);
            }
        });
    }
    catch(err){
        console.log("Error in Category Tree",err)
    }
}

function wrapInitCategory(){
    function wrapMenu(){
        //console.log('Jquery Main Category')
        var mcVM3_options = {menuId: "menu-3", alignWithMainMenu: true};
        start_v_menu(mcVM3_options)
    }
    setTimeout(wrapMenu,1000);
}

$(document).ready(function(){
    $(".button-collapse").sideNav();
    $('.collapsible').collapsible()
    $('.slider').slider();
    $('.carousel').carousel();
    $(".dropdown-button").dropdown();
    $('.modal').modal();
    $('.search-input').click(function() {
        $(this).find('input').focus();
    });
    $(window).off("unveil");
    window.init_v_menu = start_v_menu

});
